import React from 'react'

import RiquadriHome from '../components/RiquadriHome';

import {portfolio} from '../config/dati';
import{motion} from 'framer-motion';
import BottoneBack from '../components/BottoneBack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* import { brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used */

import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'



import { faLeftLong } from '@fortawesome/free-solid-svg-icons'
import BottoneBackMobile from '../components/BottoneBackMobile';

function Portfolio() {
  return (
<div /* style={stile} */ className='homeDesign'>

  <section className="metaBox">

<div id="wrapper" className='scrittaCont'>
    <h1 className="glitch titolo_home" 
data-text="LUCA SATTA">LUCA SATTA</h1>
    <span className="sub">WEB DEVELOPER - GRAPHIC DESIGN</span>
</div>




<div class="workhome">

<div>
<h2>SITI WEB</h2>
<div class="desc_lavori"> Creazione siti web professionali <br/> Landing Page - Siti vetrina </div>
</div>


<div>
<h2>APPLICAZiONI WEB</h2>
<div class="desc_lavori">Creazione Web app <br/> E-commerce - sistemi prenotazione</div>
</div>

<div>
<h2>CREAZIONE GRAFICA</h2>
<div class="desc_lavori">Elaborazione e Creazione grafica</div>
</div>

</div>

<p className='desc_home'>Creazione siti web, applicazione web - Grafica digitale e Elaborazione grafica coordinata </p>
<div className="social_link_home">

{/*   <FontAwesomeIcon icon={brands('square-facebook')} /> */}
<FontAwesomeIcon icon={faFacebook} />
<FontAwesomeIcon icon={faInstagram} />
<FontAwesomeIcon icon={faGithub} />
</div>

</section>

    <section className='Cont_rq_portfolio'>
        {
        portfolio.map((element) => {
        return (
   <motion.div
  key={element.id}
  initial={{ opacity: 0, x: 400 }}
  animate={{ opacity: 1, x: 0 }}
  exit={{ opacity: 0, x: 400 }}
  transition={{ duration: 0.7, ease: "easeInOut", delay: element.delay }}
  className='sessRq'
  >
     <RiquadriHome {...element} key="{element.id}"/>
  </motion.div>
    )
 })
        }

    </section>
    <BottoneBack Link="/"> Torna Indietro </BottoneBack>

    <BottoneBackMobile Link="/" classe="bottServiziMobile"> {/* {'\u21E6'} */}
    <FontAwesomeIcon icon={faLeftLong} />
     </BottoneBackMobile>
 </div>

  )
}

export default Portfolio