import React from 'react'

import {lavoriSitiWeb as items} from '../config/lavoriGrafica';

import '../css/portfolio.css';

import { AnimatePresence } from 'framer-motion';
import{motion} from 'framer-motion';

import {useState} from 'react';

import BottoneBack from '../components/BottoneBack';
import BottoneBackMobile from '../components/BottoneBackMobile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';


function PortSitiweb() {
  const [selectedId, setSelectedId] = useState(null);
  const [selectedTitolo, setSelectedTitolo] = useState(null);


  const [selectedTesto, setSelectedTesto] = useState(null);
  const [selectedDescrizione, setSelectedDescrizione] = useState(null);
  const [selectedPrezzo, setSelectedPrezzo] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);

  const [selectedImgDue, setSelectedImgDue] = useState(null);

  const initialDelays = items.reduce((acc, items) => {
    acc[items.id] = items.delay;
    return acc;
  }, {});

  const [delays, setDelays] = useState(initialDelays);

  const updateDelay = (id) => {
    setDelays((prevDelays) => ({
      ...prevDelays,
      [id]: 0,
    }));
  };

  const selezionaDati = (id, titolo, testo, descrizione, prezzo, img, imgDue) => {
    setSelectedId(id);
    setSelectedTitolo(titolo);
    setSelectedTesto(testo);
    setSelectedDescrizione(descrizione);
    setSelectedPrezzo(prezzo);
    setSelectedImg(img);
    setSelectedImgDue(imgDue);
    updateDelay(id);
  };


  return (
    <>
    <session className="sessSitiweb">

      <div className='testo_portfolio_loghi'>
        <h1>SITI WEB</h1>
        <p>Siti web e webApp sviluppati e realizzati da me. <br/>
        Catalogo dei miei lavori di sviluppo web, clicca sul riquadro per avere più informazioni.
        </p>     
      </div>

      <div className='cont_rq_siti'>
        {items.map((itema) => (
          <motion.div
            key={itema.id}
            initial={{ opacity: 0, x: 400 }}
            animate={{ opacity: 1, x: 0, duration: 0.4, ease: 'easeInOut' }}
            transition={{ duration: 0.4, ease: 'easeInOut', delay: delays[itema.id] }}
            exit={{ opacity: 0 }}
            layoutId={itema.id}
            onClick={() => selezionaDati(itema.id, itema.titolo, itema.testo, itema.descrizione, itema.prezzo, itema.img, itema.img2)}
            className='RqSiti'
            style={{ backgroundImage: `url(${itema.img})` }}
          >

            {/* <motion.h5>{itema.titolo}</motion.h5> */}
           <motion.h2 className='descSiti'>{itema.titolo}</motion.h2> 
          </motion.div>
        ))}
      </div>





      <AnimatePresence>
        {selectedId && selectedTitolo && selectedTesto && selectedDescrizione && selectedPrezzo && selectedImg && selectedImgDue &&(
          <motion.div
            layoutId={selectedId}
            key={selectedId}
            className={selectedId ? 'sfondoModal' : 'sfondMod'}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
          >
            <motion.div className="scheda">
              <div className='topScheda'>
            <motion.h2 className="riq_web_titolo">{selectedTitolo}</motion.h2>
            <motion.button onClick={() => setSelectedId(null)} className="bottone_chiudi">
                X
              </motion.button>
              </div>
        
          {<img src={selectedImgDue} className='riq_web_imgDue'></img>}
          
              <motion.h5 className="riq_web_testo">{selectedTesto}</motion.h5>
              <motion.p className="riq_web_desc">{selectedDescrizione}</motion.p>

              <motion.p className="riq_web_prezzo">{selectedPrezzo}</motion.p>

             
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

<BottoneBack Link="/portfolio" classe="BottLavoriPortfolio"> Torna Indietro</BottoneBack>
     

</session>

<BottoneBackMobile Link="/portfolio" classe="bottServiziMobile"> {/* {'\u21E6'} */}
    <FontAwesomeIcon icon={faLeftLong} />
     </BottoneBackMobile>

    </>
  );
}

export default PortSitiweb;