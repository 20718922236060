export const Quadrati_home = [
    {
        "id" : 1,   
        "titolo":"SERVIZI",
        "link":"/servizi",
        "delay": 0.0,
        "classeStile":"Rq Rq1 RqMobile",
        "classTitolo":"titoloRiq"
    },
    {
        "id" : 2,   
        "titolo":"PORTFOLIO",
        "link":"/portfolio",
        "delay": 0.1,
        "classeStile":"Rq Rq2 RqMobile"
    },
    {
        "id" : 3,   
        "titolo":"BIO",
        "link":"/bio",
        "delay": 0.2,
        "classeStile":"Rq Rq3 RqMobile"
    },
    {
        "id" : 4,   
        "titolo":"CONTATTI",
        "link":"/contatti",
        "delay": 0.3,
        "classeStile":"Rq Rq4 RqMobile"
    },
];


/* pagina /servizi */
export const servizi = [
    { 
        "id" : 1,   
        "titolo":"SITI WEB",
        "link":"/servizi/web",
        "delay": 0.1,
        "classeStile":"Rq Rq_servizi1 RqMobile"
    },
 
    { 
        "id" : 2,   
        "titolo":"GRAFICA DIGITALE",
        "link":"/servizi/grafica",
        "delay": 0.2,
        "classeStile":"Rq Rq_servizi2 RqMobile"
    },

{ 
    "id" : 3,   
    "titolo":"MENU DIGITALI E CATALOGHI",
    "link":"",
    "delay": 0.3,
    "classeStile":"Rq Rq_servizi3 RqMobile",
    "classTitolo":"riquadroMenu"
},

   
]


/* pagina /servizi */
export const portfolio = [
    { 
        "id" : 1,   
        "titolo":"SITI WEB",
        "link":"/portfolio/web",
        "delay": 0.1,
        "classeStile":"Rq_p Rq_portfolio3 RqMobile"
    },
 
    { 
        "id" : 2,   
        "titolo":"GRAFICA",
        "link":"/portfolio/tipigrafica",
        "delay": 0.2,
        "classeStile":"Rq_p Rq_portfolio4 RqMobile"
    },
]

/* pagina /servizi */
/* --- TIPI DI SITI WEB --- */
export const tipiSiti = [
    { "id" : 1,   
    "titolo":"Sito web One page",
    "testo" : "Pubblicizza il tuo prodotto, un evento, o la tua attività. Costi bassi e tempo di relizzazione ridotti!",
    "descrizione" : "Idele per chi vuole pubblicizzare un evento, un prodotto, o magari, per chi vuole creare un canale di recluting per lavoro sport etc. " + 
    "Consigliato anche per chi vuole pubblicizzare la propria attività e vuole un sito snello e veloce tutto in una pagina.",
    "prezzo" : "A partire da 500€",
    "classe" : " riquadroServ_web",
    "img" : "../img/tipi_siti/landing.png",
    "delay": 0.1
    },
 
    { "id" : 2,   
    "titolo":"Sito Vetrina Aziendale",

    "testo" : "Vuoi far conoscere la tua azienda o il tuo business? Questo tipo di sito web è quello che fa per te!",
    "descrizione" : "Siti web professionali, ideale per far conoscere la tua azienda, agenzia o il tuo lavoro! Il sito web è compreso di indicizzazione base, visualizzabile su tutti i dispositivi, e con grafica e funzioni create per coprire le necessità del cliente.",
    "prezzo" : "A partire da 1000€",
    "classe" : "riquadroServ_web",
    "img" : "../img/tipi_siti/Sitiaziendali.png",
    "delay": 0.2
    },


    { 
    "id" : 3,   
    "titolo":"Sito web portfolio",
    "classe" : "riquadroServ_web",

    "testo" : "Voi avere un portfolio online sempre raggiungibile in modo da far conoscere il tuo lavoro o la tua attività professionale? Ti serve un sito web portfolio!",
    "descrizione" : "Molto comodo e utile per far vedere ogni istante del tuo lavoro o della tua attività, fatti conoscere attraverso il web con un sito web professionale, con grafica e funzioni creato ad hoc per la tua attività / lavoro. Acquisisci professionalità e visione online!",
    "prezzo" : "A partire dai 500€",
    "img" : "../img/tipi_siti/portfolio.png",
    "delay": 0.6
},

{ "id" : 4,   
"titolo":"Siti Prenotazioni",
"classe" : "riquadroServ_web",

"testo" : "Sei titolare di un attività di booking? O sei possiedi un' attività che comprende prenotazioni - iscrizioni - recluting? Questo è il servizio che cercavi !!",
"descrizione" : "Questo tipo di siti web è perfetto per chi vuole acquisire prenotazioni o iscrizioni online per prenotare un servizio. Possibilità di integrare anche database (per acquisizione dati clienti) e sistema di login (autenticazione cliente). Attraverso questo servizio si può creare anche un sistema molto strutturato, in base alle necessità del cliente. ",
"prezzo" : "A partire dai 1500€",
"img" : "../img/tipi_siti/book.png",
"delay": 1.0
},


    { "id" : 5,   
"titolo":"Blog & Magazine",
"classe" : "riquadroServ_web",

"testo" : "Il servizio adatto per creare un blog personale o aziendale, o magari un magazine o fanzina online !",
"descrizione" : "Ideale se vuoi creare un blog di articoli, un magazine / rivista online, con questo sistema avrai accesso alla tua area personale dove potrai scrivere articoli, e pubblicarli sulla tua pagina web. Avrai modo di attirare lettori e far visitare il tuo sito web con una grafica creata appositamente.",
"prezzo" : "A partire da 800€",
"img" : "../img/tipi_siti/blog.png",

"delay": 1.2
},


{ "id" : 6,   
"titolo":"E-Commerce",
"classe" : "riquadroServ_web",

"testo" : "Vuoi vendere articoli sul web? Allora ti serve un e-commerce professionale.",
"descrizione" : "Se hai bisogno di vendere i tuoi articoli sul web, e ti serve una piattaforma stabile dove caricare i tuoi articoli, vedere i clienti registrati gli acquisti, e tutto quello che comporta la gestione di un e-commerce, questo è il servizio giusto ! Verrà creata una vera e propria piattaforma con area personale (dashboard) e sviluppato la parte visuale con grafica originale creata appositamente per gli articoli in vendita. ",
"prezzo" : "A partire dai 3000€",

"img" : "../img/tipi_siti/ecommerce.png",
"delay": 1.4
},
   
]

/* ------ TIPI DI GRAFICA ---- */
/* pagina /servizi */
export const tipiGrafica = [
    { "id" : 1,   
    "titolo":"Creazione logo",
    "testo" : "Crea il logo ideale per la tua azienda o attività, renditi riconoscibile tra le tante azinde con un simbolo o un immagine che rispecchi la tua attività",
    "descrizione" : "Dai un'identità alla tua azienda con un logo nuovo o crealo da zero, distinguiti dagli altri e crea la tua immagine personale che identifichi il tuo lavoro o il tuo tipo di Business. Avere un logo bello e dinamico è fondamentale per distinguerti dai tuoi competitor e aziende rivali !",
    "prezzo" : "A partire da 300€",
    "classe" : "riquadroServ_grafica",
    "img" : "../img/tipi_grafica/logoMaker.jpg",
    "delay": 0.1
    },
 
    { "id" : 2,   
    "titolo":"Grafica Coordinata",

    "testo" : "Sviluppo e creazione della grafica coordinata !",
    "descrizione" : "Consiste nella creazione e nell insiemen coerente di stile grafico, ovvero la creazione di uno stile grafico, partendo dal logo ai colori alle immagini e all'insieme deli elementi grafico-visivi che rendono inconfondibile la visione generale di un'azienda.",
    "prezzo" : "A partire da 450€",
    "classe" : "riquadroServ_grafica",
    "img" : "../img/tipi_grafica/coordinata.jpg",
    "delay": 0.2
    },


    { 
    "id" : 3,   
    "titolo":"Supporti stampabili",
    "classe" : "riquadroServ_grafica",

    "testo" : "Creazione grafica di brochure, pieghevoli e vari supporti di stampa",
    "descrizione" : "Ideli per la creazione di menu per ristoranti e/o d'asporto, oppure per pubblicizzare la tua azienda o un'evento particolare. Formati in multipiega e la possibilità di ordinare per le stampe fisiche.",
    "prezzo" : "A partire da 80€",
    "img" : "../img/tipi_grafica/SupportiGrafici.jpg",
    "delay": 0.6
},

{ "id" : 4,   
"titolo":"Locandine ed eventi",
"classe" : "riquadroServ_grafica",
"testo" : "Creazione di locandine/flyer in formato digitale e stampabili",
"descrizione" : "Sviluppo di grafiche per eventi, feste e grafica pubblicitaria, forniamo servizio di creazione sia solo in formato digitale che formato digitale + formato stampa ( anche di grandi dimensioni ).",
"prezzo" : "A partire dai 40€",
"img" : "../img/tipi_grafica/lavoroFlyer.jpg",
"delay": 1.0
},


    { "id" : 5,   
"titolo":"Grafica pubblicitaria",
"classe" : "riquadroServ_grafica",

"testo" : "Creazione di grafica a scopo pubblicitario o ornamentale di grandi dimensioni",
"descrizione" : "Sviluppo di grafiche per fine pubblicitario di grandi e medie dimensioni, adatte anche per pubblicizzare un prodotto un evento, una nuova attività, oppure a scopo decorativo, all'interno di una galleria o centro commerciale. ",
"prezzo" : "A partire dai 150€",
"img" : "../img/tipi_grafica/pubblicitaria.jpg",

"delay": 1.2
},


{ "id" : 6,   
"titolo":"Restyling e ricostruzione",
"classe" : "riquadroServ_grafica",

"testo" : "Ricostruzione logo in formato vettoriale",
"descrizione" : "Ricostruzione di un vecchio logo o restyling di progetto di logo. Se hai un vecchio logo sgranato o se vuoi stamparlo più grande della sua dimensione originale e questo 'sgrana' vuol dire che deve essere trasformato in un formato vettoriale, oppure se hai un logo o un disegno da digitalizzare professionalmente in vettoriale questo è il servizio giusto per te !",
"prezzo" : "A partire dai 150€",

"img" : "../img/tipi_grafica/ricostrLogo.jpg",
"delay": 1.4
},
   
]