import React from 'react'

/*--FONT AWESOME--*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* import { brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used */

import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'


import BottoneBack from '../components/BottoneBack';
const contatti = () => {
  return (
    <div className='homeDesign'>
  
    <section className="metaBox">
  
      <div id="wrapper" className='scrittaCont'>
          <h1 className="glitch titolo_home" 
      data-text="LUCA SATTA">LUCA SATTA</h1>
          <span className="sub">WEB DEVELOPER - GRAPHIC DESIGN</span>
      </div>
    
  
  
  
  <div class="workhome">
  
    <div>
   <h2>SITI WEB</h2>
   <div class="desc_lavori"> Creazione siti web professionali <br/> Landing Page - Siti vetrina </div>
   </div>
  
  
    <div>
   <h2>APPLICAZiONI WEB</h2>
   <div class="desc_lavori">Creazione Web app <br/> E-commerce - sistemi prenotazione</div>
   </div>
  
   <div>
   <h2>CREAZIONE GRAFICA</h2>
   <div class="desc_lavori">Elaborazione e Creazione grafica</div>
   </div>
  
  </div>
  
  <p className='desc_home'>Creazione siti web, applicazione web - Grafica digitale e Elaborazione grafica coordinata </p>
  <div className="social_link_home">
   
  {/*   <FontAwesomeIcon icon={brands('square-facebook')} /> */}
  <FontAwesomeIcon icon={faFacebook} />
    <FontAwesomeIcon icon={faInstagram} />
    <FontAwesomeIcon icon={faGithub} />
    </div>
  
    </section>
  
  
  
  <section className='sessContatti'>
    
     <p> <FontAwesomeIcon icon={faLocationDot} className='iconeContatti'/> Viale Umberto 42 - Sassari - 07100</p>
    
     <p> <FontAwesomeIcon icon={faPhoneVolume} className='iconeContatti'/> + 39 342 08 36 045</p>
  
     <p> <FontAwesomeIcon icon={faClock} className='iconeContatti'/>Dal Lunedì al Venerdì / 9:30 - 17:00</p>
     
     <p> <FontAwesomeIcon icon={faMailBulk} className='iconeContatti'/>studio@lucasatta.it / luca.satta87@hotmail.com</p>
    
     <p> <FontAwesomeIcon icon={faAddressCard} className='iconeContatti'/>P.IVA 02964510909 </p>

<div className='socialContatti'>
<p>Seguimi sui social</p>
<a href='https://www.facebook.com/lucasattadev' target='blank'>
    <FontAwesomeIcon icon={faFacebook} className='icone_socialContatti'/>
</a>
<a href='https://www.instagram.com/lucasatta_dev/' target='blank'>
    <FontAwesomeIcon icon={faInstagram} className='icone_socialContatti'/>
</a>
</div>


{/*     <FontAwesomeIcon icon={faInstagram} className='iconeContatti'/>
    <FontAwesomeIcon icon={faGithub} className='iconeContatti'/> */}
     <BottoneBack Link="/"> Torna Indietro </BottoneBack>
  </section>
  </div>
  )
}

export default contatti