
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import './css/pages.css';
import './css/components.css';


/* MIEI ELEMENTI */
import MyMenu from './components/Mymenu';
import Footer from "./components/Footer";

import Bottonewp from "./components/Bottonewp";

import CookieConsent from "react-cookie-consent";
/* -- import react router */
import { BrowserRouter as Router,} from 'react-router-dom';
import AnimatedRoutes from './components/AnimateRoutes';

/* import { ScrollRestoration } from "react-router-dom";
 */
function App() {
  return (
    <>
   
    <div className="App" id='App'>
    <div className="reticolo"></div> 
       
      <Router>
        
      <MyMenu></MyMenu> 

<AnimatedRoutes></AnimatedRoutes>

<Bottonewp></Bottonewp>
 <Footer></Footer>
</Router>



  <CookieConsent

  location="bottom"
  buttonText="Ok Acconsento"
  cookieName="myAwesomeCookieName3"
  style={{ background: "black", /* display: 'block' */ width: '100%' }}
  buttonStyle={{ backgroundColor: "rgb(0, 255, 255)", fontSize: "15px" }}
  expires={150}


>
  Questo sito web usa cookies per migliorare l'esperienza di navigazione dell'utente, ti prego di acconsentire per la migliore esperienza.
  </CookieConsent>

    </div>
    </>
  );
}

export default App;
