import React from 'react'

import {loghiLavori as items} from '../config/lavoriGrafica';

import '../css/portfolio.css';

import { AnimatePresence } from 'framer-motion';
import{motion} from 'framer-motion';

import {useState} from 'react';

import BottoneBack from '../components/BottoneBack';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';

import BottoneBackMobile from '../components/BottoneBackMobile';
function PortLoghi() {
  const [selectedId, setSelectedId] = useState(null);
  const [selectedTitolo, setSelectedTitolo] = useState(null);


  const [selectedTesto, setSelectedTesto] = useState(null);
  const [selectedDescrizione, setSelectedDescrizione] = useState(null);
  const [selectedPrezzo, setSelectedPrezzo] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedImg2, setSelectedImg2] = useState(null);

  const initialDelays = items.reduce((acc, items) => {
    acc[items.id] = items.delay;
    return acc;
  }, {});

  const [delays, setDelays] = useState(initialDelays);

  const updateDelay = (id) => {
    setDelays((prevDelays) => ({
      ...prevDelays,
      [id]: 0,
    }));
  };

  const selezionaDati = (id, titolo, testo, descrizione, prezzo, img, img2) => {
    setSelectedId(id);
    setSelectedTitolo(titolo);
    setSelectedTesto(testo);
    setSelectedDescrizione(descrizione);
    setSelectedPrezzo(prezzo);
    setSelectedImg(img);
    setSelectedImg2(img2);
    updateDelay(id);
  };


  return (
    <>
    <session className="spazioLoghi">


      <div className="sessRq Rqservizi_loghi">
      <div className='testo_portfolio_loghi'>
<h1>LOGHI</h1>
<p>Ideazione e realizzazione Logo Tipo, Restyling e ricostruzione loghi. <br/>
Catalogo dei miei lavori, clicca sul riquadro per avere più informazioni.
</p>     
</div>
        {items.map((itema) => (
          <motion.div
            key={itema.id}
            initial={{ opacity: 0, x: 400 }}
            animate={{ opacity: 1, x: 0, duration: 0.4, ease: 'easeInOut' }}
            transition={{ duration: 0.4, ease: 'easeInOut', delay: delays[itema.id] }}
            exit={{ opacity: 0 }}
            layoutId={itema.id}
            onClick={() => selezionaDati(itema.id, itema.titolo, itema.testo, itema.descrizione, itema.prezzo, itema.img, itema.img2)}
            className={itema.classe}
          >
          <img src={itema.img} class="img-quadrati-portfolio" alt={itema.titolo}></img>
            {/* <motion.h5>{itema.titolo}</motion.h5> */}
            <motion.h2 className='loghiBottoni'>{itema.titolo}</motion.h2>
          </motion.div>
        ))}
</div>





      <AnimatePresence>
        {selectedId && selectedTitolo && selectedTesto && selectedDescrizione && selectedPrezzo && selectedImg && selectedImg2 &&(
          <motion.div
            layoutId={selectedId}
            key={selectedId}
            className={selectedId ? 'sfondoModal' : 'sfondMod'}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
          >
            <motion.div className="scheda">
              <div className='topScheda'>
            <motion.h2 className="riq_web_titolo">{selectedTitolo}</motion.h2>
            <motion.button onClick={() => setSelectedId(null)} className="bottone_chiudi">
                X
              </motion.button>
              </div>
          <img src={selectedImg} className='riq_grafica_img_logo' alt={selectedTitolo}></img>
          <img src={selectedImg2} className='riq_grafica_img_logo2' alt={selectedTitolo}></img>
            
              <motion.h5 className="riq_web_testo">{selectedTesto}</motion.h5>
              <motion.p className="riq_web_desc">{selectedDescrizione}</motion.p>

              <motion.p className="riq_web_prezzo">{selectedPrezzo}</motion.p>

             
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

<BottoneBack Link="/portfolio/tipigrafica" classe="BottLavoriPortfolio"> Torna Indietro</BottoneBack>
<BottoneBackMobile Link="/portfolio/tipigrafica" classe="bottServiziMobile"> {/* {'\u21E6'} */}
    <FontAwesomeIcon icon={faLeftLong} />
     </BottoneBackMobile>
</session>



    </>
  );
}

export default PortLoghi;