export const tipiGrafica = [
    { 
        "id" : 1,   
        "titolo":"LOGHI",
        "link":"/portfolio/loghi",
        "delay": 0.1,
        "classeStile":"Rq_p Rq_portfolio_loghi RqMobile"
    },
 
    { 
        "id" : 2,   
        "titolo":"GRAFICHE",
        "link":"/portfolio/grafica",
        "delay": 0.2,
        "classeStile":"Rq_p Rq_portfolio_grafiche RqMobile"
    },
]


/* ---- LOGHI ---- */
export const loghiLavori = [
    { 
        "id" : 10,   
"titolo":"Torneo Padel",
"classe" : "Rq_loghi",

"testo" : "Torneo padel Usini",
"descrizione" : "Logo realizzato per l'evento / torneo di padel a Usini (Provincia Sassari). La richiesta del cliente è stata di creare un logo per l'evento in modo che potesse essere usato anche sulle future edizioni. Il logo è stato pensato in base all'ambito sportivo e giovanile / e anche non che avvicina questo sport, utilizzando sopratutto i colori che lo caraterizzano. Logo disegnato e sviluppato in VETTORIALE CON ADOBE ILLUSTRATOR",
"prezzo" : "LOGO TORNEOI PADEL",

"img" : "../img/loghi/logoPadel.jpg",
"img2" : "../img/loghi/padelLogoC.jpg",
"delay": 0.1
    },
 
    { 
        "id" : 1,   
"titolo":"Nereo Distribuzioni",
"classe" : "Rq_loghi",

"testo" : "NEREO DISTRIBUZIONI",
"descrizione" : "Logo realizzato per NEREO DISTRIBUZIONI. Il logo inizialmente esistente aveva molte lacune ed il cliente non riusciva a trovare il formato vettoriale, abbiamo valutato assieme di creare un restyling del logo, rendendolo più originale e dinamico, ma mantenendo gli stessi colori del precedente. Logo disegnato e sviluppato in VETTORIALE CON ADOBE ILLUSTRATOR ",
"prezzo" : "NEREO DISTRIBUZIONI",

"img" : "../img/loghi/nereoLogoNero.jpg",
"img2" : "../img/loghi/nereoLogo.jpg",
"delay": 0.1
    },
 
    { 
        "id" : 2,   
        "titolo":"HCC Sassari",
        "classe" : "Rq_loghi",
        
        "testo" : "Healt care center",
        "descrizione" : "Questo logo è stato sviluppato con un'idea diversa da cosa avrebbe voluto il cliente, difatti esso si è presentato con un'idea che non si poteva incastrare bene con il suo progetto di azienda in ambito ospedaliero / medico. Lo sviluppo di questo ogo deriva dall'idea di fondere le iniziali del nome dell'azienda e creare un monogramma simile ad un'omino che salta con le braccia in aria (sintomo dello star bene e di gioiosità). Logo disegnato e sviluppato in VETTORIALE CON ADOBE ILLUSTRATOR ",
        "prezzo" : "HHC SASSARI",
        
        "img" : "../img/loghi/hccLogoNero.jpg",
        "img2" : "../img/loghi/hccLogo.jpg",
        "delay": 0.2
    },

    { 
        "id" : 3,   
        "titolo":"Gastronomia (Isola Rossa)",
        "classe" : "Rq_loghi",
        
        "testo" : "Gastronomia Isola Rossa",
        "descrizione" : "Logo realizzato con dettagliate istruzioni del cliente, il suo logo doveva rappresentare un riccio marino, o meglio la metamorfosi di un guscio di un ricco, le reference per creare il logo le ho reperite dal cliente mentre invece la realizzazione, è stata studiata attraverso una serie di bozzetti per poi essere stata raffinata in versione digitale, vettoriale. Logo disegnato e sviluppato in VETTORIALE CON ADOBE ILLUSTRATOR",
        "prezzo" : "GASTRONOMIA ISOLA ROSSA",
        
        "img" : "../img/loghi/gastronomiaIsolaNero.jpg",
        "img2" : "../img/loghi/gastronomiaIsola.jpg",
        "delay": 0.3
    },

    { 
        "id" : 4,   
        "titolo":"SAT Sirbones (Softair)",
        "classe" : "Rq_loghi",
        
        "testo" : "S.A.T Sirbones",
        "descrizione" : "Logo costruito per il gruppo di soft air SAT SIRBONES (Portotorres -Sassari). Le reference del cliente sono state chierano, prendere il vecchio logo e creare uno stemma, nuovo logo da poter usare per il loro gruppo. Gli elementi che hanno voluto rappresentare sono stati, il cinghiale, la torre aragonese di Porto Torres e i fucili da soft air. Logo disegnato e sviluppato in VETTORIALE CON ADOBE ILLUSTRATOR ",
        "prezzo" : "SAT SIRBONES€",
        
        "img" : "../img/loghi/logoSatNero.jpg",
        "img2" : "../img/loghi/logoSat.jpg",
        "delay": 0.4
    },

    { 
        "id" : 5,   
        "titolo":"Tortuga Ristobar",
        "classe" : "Rq_loghi",
        
        "testo" : "titolo6",
        "descrizione" : "hdihiuudiuw",
        "prezzo" : "TORTUGA RISTOBAR",
        
        "img" : "../img/loghi/tortugaLogo.jpg",
        "img2" : "../img/loghi/tortCol.jpg",
        "delay": 0.5
    },

    { 
        "id" : 6,   
        "titolo":"OTL Outlet",
        "classe" : "Rq_loghi",
        
        "testo" : "titolo6",
        "descrizione" : "hdihiuudiuw",
        "prezzo" : "OTL OUTLET",
        
        "img" : "../img/loghi/otlLogoNero.jpg",
        "img2" : "../img/loghi/otlLogo.jpg",
        "delay": 0.6
    },
    { 
        "id" : 7,   
        "titolo":"Insonnia CBD",
        "classe" : "Rq_loghi",
        
        "testo" : "titolo6",
        "descrizione" : "hdihiuudiuw",
        "prezzo" : "INSONNIA CBD",
        
        "img" : "../img/loghi/insonniaLogo.jpg",
        "img2" : "../img/loghi/insonniaLogoC.jpg",
        "delay": 0.7
    },
    { 
        "id" : 8,   
        "titolo":"Il Diamante Pizzeria",
        "classe" : "Rq_loghi",
        
        "testo" : "titolo6",
        "descrizione" : "hdihiuudiuw",
        "prezzo" : "DIAMANTE PIZZERIA",
        
        "img" : "../img/loghi/ilDiamanteLogo.jpg",
        "img2" : "../img/loghi/ilDiamantePizzeria.jpg",
        "delay": 0.8
    },
    { 
        "id" : 9,   
        "titolo":"Studio Dentistico",
        "classe" : "Rq_loghi",
        
        "testo" : "titolo6",
        "descrizione" : "hdihiuudiuw",
        "prezzo" : "A partire dai 200€",
        
        "img" : "../img/loghi/studioDentisticoLogoNero.jpg",
        "img2" : "../img/loghi/studioDentisticoLogo.jpg",
        "delay": 0.9
    },
/*     { 
        "id" : 10,   
        "titolo":"Restyling e ricostruzione",
        "classe" : "Rq Rq_loghi",
        
        "testo" : "titolo6",
        "descrizione" : "hdihiuudiuw",
        "prezzo" : "A partire dai 200€",
        
        "img" : "../img/loghi/hcclogo.jpg",
        "delay": 1.0
    }, */

]



/* ---- LAVORI DI GRAFICA ---- */
export const lavoriGrafica = [
    { 
        "id" : 1,   
"titolo":"Bergotto Bibite",
"classe" : "Rq_loghi",

"testo" : "lavoro eseguito per nereo distribuzioni",
"descrizione" : "Lavoro eseguito per NEREO DISTRIBUZIONI - pubblicità nuova bibita calabrese BERGOTTO, sono stati creati dei cartelloni pubblicitarie di grandi dimensioni, varie grafiche per il web e grafica per led wall",
"prezzo" : "GRAFICA PUBBLICITARIA",

"img" : "../img/lavoroGrafica/bergottoCartellone.jpg",
"img2" : "../img/lavoroGrafica/bergotto2.jpg",
"delay": 0.1
    },
 
    { 
        "id" : 2,   
        "titolo":"Torneo Padel",
        "classe" : "Rq_loghi",
        
        "testo" : "Grafica per torneo di padel",
        "descrizione" : "Grafica e design magliette elaborate e sviluppate appositamente per il torneo di padel a Usini (Sassari). Le maglie sono state sviluppate in coordinazione con la grafica del logo e comprendono di fronte il logo degli organizzatori dell'evento, inioltre sono stati creati anche locandine ad hoc. e gadget.",
        "prezzo" : "Grafica Coordinata",
        
        "img" : "../img/lavoroGrafica/padelMagl.jpg",
        "img2" : "../img/lavoroGrafica/padelLoc.jpg",
        "delay": 0.2
    },

    { 
        "id" : 3,   
        "titolo":"Flyer openspace",
        "classe" : "Rq_loghi",
        
        "testo" : "Locandine / Flyer per scuola di danza opensapace (terni - roma) ",
        "descrizione" : "Locandine / Flyer create appositamente per pubblicizzare i corsi di danza per la palestra OPENSPACE sita a Terni (Roma) per gli anni accademici di danza 2022 e 2023.",
        "prezzo" : "Flyer / locandine",
        
        "img" : "../img/lavoroGrafica/openspace23.jpg",
        "img2" : "../img/lavoroGrafica/openspace.jpg",
        "delay": 0.3
    },

    { 
        "id" : 4,   
        "titolo":"Caffè teatro",
        "classe" : "Rq_loghi",
        
        "testo" : "Grafica decorativa e applicazione su Forex",
        "descrizione" : "Grafica sviluppata e realizzata su supporto tabellone in forex, per Caffè Teatro Sassari. Grafica a scopo pubblicitario e decorativo.",
        "prezzo" : "Grafica su grande / medio formato",
        
        "img" : "../img/lavoroGrafica/caffeTeatro.jpg",
        "img2" : "../img/lavoroGrafica/caffeTeatro2.jpg",
        "delay": 0.4
    },

    { 
        "id" : 5,   
        "titolo":"La Roccia Spot",
        "classe" : "Rq_loghi",
        
        "testo" : "LAVORO ESEGUITO PER 'LA ROCCIA' PIZZERIA",
        "descrizione" : "Pubblicità per pizzeria ristorante LA ROCCIA, la grafica è stata creata ad hoc per seguire le modalità di marketing del cliente, è stata studiata una grafica accativante che attirasse la curiosità.",
        "prezzo" : "GRAFICA PUBBLICITARIA",
        
        "img" : "../img/lavoroGrafica/laRocciaTram.jpg",
        "img2" : "../img/lavoroGrafica/graficaRoccia.jpg",
        "delay": 0.5
    },

    { 
        "id" : 6,   
        "titolo":"Steakers e Brochure",
        "classe" : "Rq_loghi",
        
        "testo" : "Steaker adesivi per rado tattoo",
        "descrizione" : "Creazione e elaborazsione Steakers / adesivi ressistenti e professionali per Rado Tattoo Sassari.",
        "prezzo" : "A partire dai 200€",
        
        "img" : "../img/lavoroGrafica/steakersRado.jpg",
        "img2" : "../img/lavoroGrafica/steakerRado.jpg",
        "delay": 0.6
    },
    { 
        "id" : 7,   
        "titolo":"OTL Outlet",
        "classe" : "Rq_loghi",
        
        "testo" : "Grafiche di grande formato per il negozio OTL Outlet",
        "descrizione" : "Creazione e realizzazione stampe e grafiche di grande formato, per il cliente OTL Outlet a Sassari. Grafica pensata e realizzata per poter attirare lk'attenzione di potenziali clienti, difatti il negozio è sito nella zona industriale di sassari dove c'è poco passaggio pedonale e più traffico di automobili e mezzi.",
        "prezzo" : "A partire dai 200€",
        
        "img" : "../img/lavoroGrafica/otlDesignNegozio.jpg",
        "img2" : "../img/lavoroGrafica/insegnaOutlet.jpg",
        "delay": 0.7
    },
    { 
        "id" : 8,   
        "titolo":"OTL Outlet",
        "classe" : "Rq_loghi",
        
        "testo" : "titolo6",
        "descrizione" : "hdihiuudiuw",
        "prezzo" : "A partire dai 200€",
        
        "img" : "../img/lavoroGrafica/vibes.jpg",
        "img2" : "../img/lavoroGrafica/vibes2.jpg",
        "delay": 0.7
    },
 /*    { 
        "id" : 8,   
        "titolo":"Flyer music event",
        "classe" : "Rq_loghi",
        
        "testo" : "titolo6",
        "descrizione" : "hdihiuudiuw",
        "prezzo" : "A partire dai 200€",
        
        "img" : "../img/loghi/ilDiamanteLogo.jpg",
        "delay": 0.8
    },
    { 
        "id" : 9,   
        "titolo":"Studio Dentistico",
        "classe" : "Rq_loghi",
        
        "testo" : "titolo6",
        "descrizione" : "hdihiuudiuw",
        "prezzo" : "A partire dai 200€",
        
        "img" : "../img/loghi/studioDentisticoLogoNero.jpg",
        "delay": 0.9
    },
 */


    

]


/* ---- LAVORI DI GRAFICA ---- */
export const lavoriSitiWeb = [
    { 
        "id" : 1,   
"titolo":"Radotattoo.it",
"classe" : "",

"testo" : "www.radotattoo.it",
"descrizione" : "Sito web sviluppato con il framework/libreria React.js, per Rado Tattoo. Viene sviluppato come un sito web vetrina / portfolio, sviluppato nel 2022 il sito web è ancora attivo.",
"prezzo" : "Sito web vetrina portfolio",

"img" : "../img/lavoroSiti/mockupRadoTattoo.jpg",
"img2" : "../img/lavoroSiti/mockupRadoTattoo.jpg",
"delay": 0.1
    },
 
    { 
        "id" : 2,   
        "titolo":"nuovaserel.it",
        "classe" : "",
        
        "testo" : "www.nuovaserel.it",
        "descrizione" : "Sito web multipagina sviluppato per l'azienda nuovaserel. Il sito web è stato sviluppato con linguaggi html css e javascript, mentro la parte backend è in php (routing, sendmail, controller). il sito web è stato sviluppato nel 2023 ed è in attività.",
        "prezzo" : "Sito multipagina",
        
        "img" : "../img/lavoroSiti/mockupNuovaSerel.jpg",
        "img2" : "../img/lavoroSiti/mockupNuovaSerel.jpg",
        "delay": 0.2
    },

    { 
        "id" : 3,   
        "titolo":"ciafciafcharter.it",
        "classe" : "Rq Rq_loghi",
        
        "testo" : "ciafciafcharter.it",
        "descrizione" : "Sito web multipagina e multilingua, viene sviluppato dalla necessità del cliente di far conoscere la sua nuova azienda all'interno del territorio, e il sito funge da sito web vetrina aziendale, con la possibilità di sviluppare anche un sistema di prenotazione personalizzato. Il sito web è stato sviluppato senza l uso di cms con codice HTML, CSS, JAVASCRIPT, PHP e varie librerie di frontend",
        "prezzo" : "Sito web multilingua",
        
        "img" : "../img/lavoroSiti/mockupCiafciaf.jpg",
        "img2" : "../img/lavoroSiti/mockupCiafciaf.jpg",
        "delay": 0.3
    },

    { 
        "id" : 4,   
        "titolo":"eyedrone4-0.it",
        "classe" : "Rq Rq_loghi",
        
        "testo" : "www.Eyedrone4-0.it",
        "descrizione" : "Sito web multipagiona sviluppato nel 2022 per l'azienda EYEDRONE 4.0, che si occupa di rilevamenti e studio di territori, a terra ed in aria attraverso l'uso dei droni. Il sito web è stato sviluppato dalla necessità di farsi conoscere nel territorio e fungere attraverso un form di recuperare le utenze dei clienti. Il sito web è stato sviluppato senza l'uso di CMS con i linguaggi HTML, CSS, JAVASCRIT E PHP.",
        "prezzo" : "Sito web aziendale",
        
        "img" : "../img/lavoroSiti/mockupEyedrone.jpg",
        "img2" : "../img/lavoroSiti/mockupEyedrone.jpg",
        "delay": 0.4
    },

    { 
        "id" : 5,   
        "titolo":"Insonniacbd.com",
        "classe" : "Rq Rq_loghi",
        
        "testo" : "WWW.INSONNIACBD.COM",
        "descrizione" : "Sito web E-commerce con blog integrato, è dato dalla necessità del cliente di vendere i propri prodotto, ed è stato svilupopato per l'azienda INSONNIA CBD. Il sito web è stato sviluppato con una base del cms prestashop e serializzato e personalizzato con codice HTML, JAVASCRIPT, CSS E PHP, alcune funzioni sono state completamente personalizzate in base all'esigenza ed il template e l'aspetto grafico pensato e creato appositamente.",
        "prezzo" : "Sito web e-commerce",
        
        "img" : "../img/lavoroSiti/mockupInsonnia.jpg",
        "img2" : "../img/lavoroSiti/mockupInsonnia.jpg",
        "delay": 0.5
    },

    { 
        "id" : 6,   
        "titolo":"Sevenmenu.it",
        "classe" : "Rq Rq_loghi",
        
        "testo" : "www.sevenmenu.it",
        "descrizione" : "Siro web sviluppato per SEVEN MENU menu digitali. La creazione di questo sito serve per illustrare al cliente il risparmio e la convenienza di scegliere il servizio di menu digitale SEVENMENU. Il sito web è un sito vetrina conb modulo di contatto (form) dove il cliente può contattare e chiedere un preventivo. Il sito web è stato sviluppato con i linguaggi della rete HTML, CSS, JAVASCRIPT e PHP per gestire la richiesta del form di contatto per il cliente.",
        "prezzo" : "Sito web Azindale",
        
        "img" : "../img/lavoroSiti/mockupSevenmenu.jpg",
        "img2" : "../img/lavoroSiti/mockupSevenmenu.jpg",
        "delay": 0.6
    },
    { 
        "id" : 7,   
        "titolo":"ottaviodiesismanca.it",
        "classe" : "Rq Rq_loghi",
        
        "testo" : "ottaviodiesismanca.it",
        "descrizione" : "Sito web realizzato per lo scrittore Ottavio Manca in arte 'diesis'. Il sito è nato per la necessità di pubblicizzare il nuoco libro dell'autore, e di conseguenza per collegare l'utente alle piattaforme di vendita dei libri dove è possibile appunto comprare il libro dell'autore. Il sito web è stato sviluppato nel 2021, è ancora in attivitò. Sviluppo senza uso di cms con codici HTML, CSS, JAVASCRIPT e PHP per gestire l' invio del form da parte del cliente. ",
        "prezzo" : "Sito web multipagina vetrina",
        
        "img" : "../img/lavoroSiti/mockupOttavio.jpg",
        "img2" : "../img/lavoroSiti/mockupOttavio.jpg",
        "delay": 0.7
    },
    { 
        "id" : 8,   
        "titolo":"tiriccaholidayhome.it",
        "classe" : "Rq Rq_loghi",
        
        "testo" : "tiriccaholidayhome.it",
        "descrizione" : "Sito web multilingua tutto in una pagina, il sito funziona da landing page (pagina di atterraggio) dove l'utente, in una pagina può reperire tutte le informazioni che li interessano, compresa mappa, modulo di prenotazione e/o collegamento con l'utente e le varie informazioni relative al sito web. Il sito web è stato realizzato in HTML, CSS, JAVASCRIPT e PHP (per la gestione del multilingue e la gestione del form di collegamento).",
        "prezzo" : "Landing page multilingua",
        
        "img" : "../img/lavoroSiti/mockupTiricca.jpg",
        "img2" : "../img/lavoroSiti/mockupTiricca.jpg",
        "delay": 0.8
    },

]