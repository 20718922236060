import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const Bottonewp = () => {
  return (
    
    <div className='bottoneWP'>
        <a href='https://wa.me/+393420836045'>
          <FontAwesomeIcon icon={faWhatsapp}  className='simboloWp'/>
        </a>
    </div>
  )
}

export default Bottonewp