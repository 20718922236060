import React from 'react';
/* import logo from '../../public/img/logo.png'; */

function Footer() {
  return (
    <div className='footer'>
      <div className='foot col1'> 
        <img src='../img/easycash.png'></img>
      </div>

        <div className='foot col2'></div>

        <div className='foot col3'> 
        <p> Tutti i diritti di questo sito web, e i suoi contenuti sono esclusivamente riservati <br/>   &copy; lucasatta.it </p> 
         
        </div>
    </div>
  )
}

export default Footer