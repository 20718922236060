import React from 'react';
/* import MyMenu from '../components/Mymenu'; */
import '../css/responsive.css';
import '../css/glitch.css';
import '../css/riquadriHome.css';


/*--FONT AWESOME--*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* import { brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used */

import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

import {motion} from 'framer-motion'

import Trans from '../tran/Trans';
/*--ELEMENTI--*/
import {Quadrati_home} from '../config/dati';
import RiquadriHome from '../components/RiquadriHome';


/*--IMMAGINI--*/
/* import SfondoBio from '../../public/img/sfondiHome/bio3.jpg'; */

function Home() {
  return (
  <>
<div className='homeDesign'>
  
  <section className="metaBox">

	<div id="wrapper" className='scrittaCont'>
		<h1 className="glitch titolo_home" 
    data-text="LUCA SATTA">LUCA SATTA</h1>
		<span className="sub">WEB DEVELOPER - GRAPHIC DESIGN</span>
	</div>
  



<div class="workhome">

  <div>
 <h2>SITI WEB</h2>
 <div class="desc_lavori"> Creazione siti web professionali <br/> Landing Page - Siti vetrina </div>
 </div>


  <div>
 <h2>APPLICAZiONI WEB</h2>
 <div class="desc_lavori">Creazione Web app <br/> E-commerce - sistemi prenotazione</div>
 </div>

 <div>
 <h2>CREAZIONE GRAFICA</h2>
 <div class="desc_lavori">Elaborazione e Creazione grafica</div>
 </div>

</div>

<p className='desc_home'>Creazione siti web, applicazione web - Grafica digitale e Elaborazione grafica coordinata </p>
<div className="social_link_home">
 
{/*   <FontAwesomeIcon icon={brands('square-facebook')} /> */}
<FontAwesomeIcon icon={faFacebook} />
  <FontAwesomeIcon icon={faInstagram} />
  <FontAwesomeIcon icon={faGithub} />
  </div>

  </section>



<section className='sessRqContenitore'>

{
 Quadrati_home.map((elem)=> {
/*   console.log(elem.id); */

return ( <motion.div
  key={elem.id}
  initial={{ opacity: 0, x: 400 }}
  animate={{ opacity: 1, x: 0 }}
  exit={{ opacity: 0, x: 400 }}
  transition={{ duration: 0.7, ease: "easeInOut", delay: elem.delay }}
  className='sessRq'
>
 <RiquadriHome {...elem} />
</motion.div>
)
 })
}

</section>
</div>



  </>
  )
}

export default Home





