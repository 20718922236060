import React from 'react';

import {Route, Routes} from 'react-router-dom';

import { useLocation } from 'react-router-dom';

/* -- import pagine */
import Home from '../routes/Home';
import Bio from '../routes/Bio';
import Portfolio from '../routes/Portfolio';
import Servizi from '../routes/Servizi';
import Web from '../routes/Web';
import Grafica from '../routes/Grafica';
import Tipigrafica from '../routes/Tipigrafica';

import PortLoghi from '../routes/PortLoghi';
import PortGrafica from '../routes/PortGrafica';

import PortSitiweb from '../routes/portSitiweb';

import Contatti from '../routes/contatti';


import { AnimatePresence } from 'framer-motion';


import ScrollToTop from "./ScrollToTop";



const AnimatedRoutes = () => {

/*   const location = useLocation(); */

  return (


<AnimatePresence>
{/* <ScrollToTop></ScrollToTop>  */}
    <Routes>
     
      <Route path="/" element={<Home/>} />
      <Route path="/bio" element={<Bio/>}/>
      <Route path="/portfolio" element={<Portfolio/>}/>
 <Route path="/servizi" element={<Servizi/>}/>
 <Route path="/contatti" element={<Contatti/>}/>
 <Route path="/servizi/web" element={<Web/>}/>
 <Route path="/servizi/grafica" element={<Grafica/>}/>
 <Route path="/portfolio/tipigrafica" element={<Tipigrafica/>}/>

 <Route path="/portfolio/web" element={<PortSitiweb/>}/>

 <Route path="/portfolio/loghi" element={<PortLoghi/>}/>
 <Route path="/portfolio/grafica" element={<PortGrafica/>}/>

 {/*      <Route path="/about" element={<About/>}/>
      <Route path="*" element={<NotFound/>}/>  */}
    </Routes>


    </AnimatePresence>
  )
}

export default AnimatedRoutes