import React from 'react'
import{Container, Row, Col} from 'react-bootstrap';

import BottoneBackMobile from '../components/BottoneBackMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong } from '@fortawesome/free-solid-svg-icons';

function Portfolio() {
  return (
    <div className='pagina_bio'>
      
      <Container fluid>
      <Row>
        <Col>
        <h3 className="glitch titolo_bio" data-text="BIOGRAFIA">
          BIOGRAFIA
        </h3>
       
       <div className='contenitoreBio'>
        <p className='paragrafo_bio'>
        Luca Satta nato a Sassari nel 1987, ho conseguito il diploma di Perito informatico nel 2007, e da li ho proseguito i miei studi nell'ambito dell' informatica.
        Nel 2008 ho intrapreso un corso di web design e grafica digitale, con lo scopo di apprendere le basi del web e le tecniche della grafica,
        attraverso l'uso di software ad hoc, adobe@. attraverso ciò ho conseguito con successo la certificazione adobe per i moduli adobe photoshop, adobe dreamweaver, adobe firework.
        Gli anni di studi tramite corsi online, altri corsi privati, formazione personale e esperienza lavorativa,
        hanno potuto arricchire le mie "skills", oltre a darmi nuove nozioni sui linguaggi e i framework.
        <hr></hr>
        Ora mi occupo di sviluppo web front-end e back-end e grafica, creazione di siti 
        internet anche con l'uso di cms (quali wordpress), servizi web, 
        grafica e modellazione e creazione immagini digitali, ad oggi sempre si
         ricerca e si studia ancora con lo stesso impegno e la stessa passione di prima.
        Sono sempre stato attirato dalla scoperta, dalle nuove invezioni, 
        reputo sia una forma d'arte, e penso che Il progresso tecnologico 
        nell’ambito dell’informatica ha aperto nuove ed inaspettate prospettive di sviluppo alle potenzialità e alle capacità creative ed operative dell’uomo, in molti e differenziati settori della sua vita sociale.
        </p>
        </div>
       
      <div className='logoBio'>
      <img src='../img/easycash.png' alt='logo easycash'></img>
      </div>

        </Col>
        
        <Col>
        <div className='parteSkills'>
        <h3 className="glitch titolo_bio" data-text="SKILLS">
          SKILLS
        </h3>

        <div className='programmingBio'>
          <h4>PROGRAMMAZIONE WEB</h4>
          <p>Sviluppo web Front-end e back-end con tutte le tecnologie acquisite durante 
            i miei lavori e i miei studi in più di 7 anni di esperienza</p>

          <h5>FRONT-END</h5>
          <div className='loghiStrumenti'>
          <img src='../img/htmllogo.png' alt='logohtml'></img>
          <img src='../img/csslogo.png' alt='logo css'></img>
          <img src='../img/jslogo.png'  alt='logo javascript'></img>
          <img src='../img/reactlogo.png'  alt='logoreact'></img>
          </div>
        
        <br></br>

          <h5>BACK-END</h5>
          <div className='loghiStrumenti'>
          <img src='../img/phplogo.png'  alt='logo php'></img>
          <img src='../img/laravellogo.png' alt='logo laravel'></img>
          <img src='../img/nodelogo.png' alt='logo node'></img>
          </div>
        </div>

        <div className='graphicsBio'>
        <h4>GRAFICA</h4>
        <p>Realizzazione e sviluppo grafica di tipo raster e grafica di tipo vettoriale, gli strumenti digitali professionali che uso,
           e che ho imparato ad usare in più di 12 anni di esperienza.</p>

        <div className='loghiStrumenti'>
          <img src='../img/pslogo.png'  alt='logo ps'></img>
          <img src='../img/ailogo.png' alt='logo ai'></img>
     
          </div>

        </div>

        </div>
        </Col>
      </Row>
    </Container>


    <BottoneBackMobile Link="/" classe="bottServiziMobile"> {/* {'\u21E6'} */}
    <FontAwesomeIcon icon={faLeftLong} />
     </BottoneBackMobile>

    </div>
  )
}

export default Portfolio