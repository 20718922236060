import React from 'react'
import { Link } from "react-router-dom";
/* import '../css/riquadriHome.css'; */

function RiquadriHome({titolo, link, classeStile, /* img , */ classTitolo}) {

  return (
    <>

    <Link to={`${link}`} className={`${classeStile}`}>
    
        <div className="dentroRq">
          
    {/*   { img && 
         <img src={`${img}`} className='imgRQ'/>
      }
          */}
       
        <div className="wrapper2 scrittaRq">
		      <h2 className={classTitolo}>{titolo}</h2>
	         </div>
           </div>
     </Link>
     
    </>
  )
}

export default RiquadriHome