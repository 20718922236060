import {Button } from 'react-bootstrap';
import React from 'react'
import { Link } from "react-router-dom";



function BottoneBackMobile(props) {
  return (
    <Link to={props.Link}>
    <Button 
      className={props.classe ? props.classe : 'bottoneBackMobile'}
    >
     <p>
      {
  props.children
      }
 </p>
    </Button>

    </Link>
  )
}

export default BottoneBackMobile